import { CouponState, ErrorType, SET_COUPON_CODE, SET_COUPON_IS_LOADING, SET_ERROR_TYPE, SET_SHOULD_AUTO_APPLY } from './types'

export const mutations = {
  [SET_COUPON_CODE] (state: CouponState, couponCode: string|null) {
    state.couponCode = couponCode
  },
  [SET_ERROR_TYPE] (state: CouponState, errorType: ErrorType) {
    state.errorType = errorType
  },
  [SET_COUPON_IS_LOADING] (state: CouponState, isLoading: boolean) {
    state.isLoading = isLoading
  },
  [SET_SHOULD_AUTO_APPLY] (state: CouponState, shouldAutoApply: boolean) {
    state.shouldAutoApply = shouldAutoApply
  }
}
