import { DefaultBoolean, DefaultString } from 'widgets-shared-services'

export const PREVIEW_MODE = 'mode/PREVIEW_MODE'
export const SET_SCREEN = 'mode/SET_SCREEN'
export const SET_INVALID_EMAIL_MODE = 'mode/SET_INVALID_EMAIL_MODE'

export interface AppModeState {
  reflection: boolean
  screen: string
  isEmailActivation: boolean
  isReadOnly: boolean
  isPreview: boolean
  invalidEmail: boolean
  notEligible: boolean
  alreadyUsed: boolean
  generalFailure: boolean
  applePrivateRelayIp: boolean
}

export interface AppModeStateCustomizations {
  reflection: DefaultBoolean
  screen: DefaultString
  isEmailActivation: DefaultBoolean
  isReadOnly: DefaultBoolean
  isPreview: DefaultBoolean
  invalidEmail: DefaultBoolean
  notEligible: DefaultBoolean
  alreadyUsed: DefaultBoolean
  generalFailure: DefaultBoolean
  applePrivateRelayIp: DefaultBoolean
}
