import { CommerceCloudCart, LoyaltyApi } from 'loyalty-shared-services'
import { LocalStorage } from 'widgets-shared-services'
import { CouponService } from './types'

export class CommerceCloudCoupon implements CouponService {
  private cart: CommerceCloudCart
  private loyaltyApi: LoyaltyApi
  private guid: string

  constructor (cart: CommerceCloudCart, loyaltyApi: LoyaltyApi, guid: string) {
    this.cart = cart
    this.loyaltyApi = loyaltyApi
    this.guid = guid
  }

  async applyCoupon (couponCode: string): Promise<any> {
    const cartDetails = this.cart.getCartDetails()
    if (!cartDetails || !cartDetails.id || !cartDetails.token) {
      return
    }

    LocalStorage.remove('loyalty-coupon-code')
    return this.loyaltyApi.addCommerceCloudCoupon(this.guid, cartDetails.id, cartDetails.token, couponCode)
  }
}
