










































































import { Vue, Component } from 'vue-property-decorator'
import CopyCodeButton from '../widgets/CopyCodeButton.vue'
import CustomizedText from '../widgets/CustomizedText.vue'
import ErrorMessage from '../widgets/ErrorMessage.vue'
import ActionButton from '../widgets/ActionButton.vue'
import BallsLoader from '../widgets/BallsLoader.vue'
import { TRACK_COPY_CODE_BUTTON_CLICKED, TRACK_START_BUTTON_CLICKED } from '@/store/modules/analytics/types'
import { FontService } from 'widgets-shared-services'
import { ErrorType } from '@/store/modules/coupon/types'
import { SET_LAST_ELEMENT } from '@/store/modules/view/types'

@Component({
  components: {
    CopyCodeButton,
    CustomizedText,
    ActionButton,
    ErrorMessage,
    BallsLoader
  }
})

export default class ReferredFriendCouponContainer extends Vue {
  private copyCodeRef!: HTMLElement
  mounted () {
    this.$store.commit(SET_LAST_ELEMENT, '.yotpo-widget-referred-friend .yotpo-start-button .yotpo-button-style')
  }
  get content () {
    return this.$store.state.content
  }
  get view () {
    return this.$store.state.view
  }
  get coupon () {
    return this.$store.state.coupon
  }
  errorType () {
    return ErrorType
  }
  get errorMessage () {
    if (this.coupon.errorType === ErrorType.NotEligible) {
      return this.content.notEligibleCouponText
    } else if (this.coupon.errorType === ErrorType.UsedCoupon) {
      return this.content.alreadyUsedCouponText
    } else if (this.coupon.errorType === ErrorType.General) {
      return this.content.generalFailureText
    } else if (this.coupon.errorType === ErrorType.IsApplePrivateRelayIp) {
      return this.templatedContent(this.content.isApplePrivateRelayIpText)
    }
  }
  get isLinkableError () {
    return this.coupon.errorType === ErrorType.IsApplePrivateRelayIp
  }
  get titleFontSize () {
    const fontSize = this.view.title.font.size
    if (this.view.isMobile) {
      return FontService.calulcateMobileFontSize(fontSize) + 'px'
    }
    return fontSize
  }
  get backgroundImage () {
    let backgroundImage = this.view.backgroundImageUrl
    if (this.view.isMobile || this.view.isIpadPortrait) {
      backgroundImage = this.view.backgroundImageUrlMobile
    }
    return {
      backgroundImage: `url('${backgroundImage}')`
    }
  }
  get copyCodeIconUrl () {
    if (this.view.copyCodeIconType !== 'custom') {
      return ''
    }

    return this.content.copyCodeIconUrl
  }
  get templatedContent () {
    return this.$store.getters.templatedContent
  }
  onStartButtonClicked () {
    this.$store.dispatch(TRACK_START_BUTTON_CLICKED)
    this.$emit('startButtonClicked')
  }
  onCodeCopied () {
    (this.copyCodeRef as HTMLElement).focus()
    this.$store.dispatch(TRACK_COPY_CODE_BUTTON_CLICKED)
  }
  setCopyCodeRef (copyCodeRef: HTMLElement) {
    this.copyCodeRef = copyCodeRef
  }
}
