import {
  FETCH_LOGGED_IN_CUSTOMER, ContextCommit, SET_LOGGED_IN_CUSTOMER, LOCAL_STORAGE_REFERRED_EMAIL_KEY, ON_CUSTOMER_EMAIL_SUBMIT
} from './types'
import { LoggedInCustomerInfoProvider, LoggedInCustomerInfo } from 'loyalty-shared-services'
import { FETCH_COUPON_CODE } from '../coupon/types'
import { SET_SCREEN } from '../mode/types'
import { Screens } from '@/models/screens'
import { LocalStorage } from 'widgets-shared-services'

export const actions = {
  async [FETCH_LOGGED_IN_CUSTOMER] ({ dispatch, commit, rootState, getters }: ContextCommit) {
    if (rootState.mode.isReadOnly) {
      dispatch(FETCH_COUPON_CODE)
      return
    }
    if (rootState.mode.isPreview) {
      if (rootState.mode.isEmailActivation) {
        commit(SET_SCREEN, Screens.EmailCapture)
        return
      }
      commit(SET_SCREEN, Screens.CouponCode)
      dispatch(FETCH_COUPON_CODE)
    }

    let loggedInCustomerInfo: LoggedInCustomerInfo | undefined
    try {
      loggedInCustomerInfo = await new LoggedInCustomerInfoProvider(getters.bigCommerceApi).fetchLoggedInCustomerInfo(rootState.platform.name)
    } catch (exception) {
      console.error(`couldn't fetch logged in customer info. exception: ${exception}`)
    }
    let email = loggedInCustomerInfo ? loggedInCustomerInfo.email : ''
    if (!email || email === '') {
      email = LocalStorage.get(LOCAL_STORAGE_REFERRED_EMAIL_KEY)
    }
    if (email) {
      commit(SET_LOGGED_IN_CUSTOMER, email)
    } else if (rootState.mode.isEmailActivation) {
      commit(SET_SCREEN, Screens.EmailCapture)
      return
    }
    commit(SET_SCREEN, Screens.CouponCode)
    dispatch(FETCH_COUPON_CODE)
  },

  async [ON_CUSTOMER_EMAIL_SUBMIT] ({ dispatch, commit }: ContextCommit, email: string) {
    LocalStorage.set(LOCAL_STORAGE_REFERRED_EMAIL_KEY, email)
    commit(SET_LOGGED_IN_CUSTOMER, email)
    commit(SET_SCREEN, Screens.CouponCode)
    dispatch(FETCH_COUPON_CODE)
  }
}
