import Vue from 'vue'
import App from './App.vue'
import StoreCreator from './store/modules/main'
import { Widget, MerchantMetadata } from 'widget-initializer/src/index'
import { StaticContent } from './store/modules/static-content/types'
import { WidgetOptions } from 'widget-initializer/src/interfaces/widget-options'
import { dispatchTrackEvent } from './store/modules/analytics/actions'
import { ReferredFriendWidgetEvent } from './models/referred-friend-widget-event'
import { MapObject } from 'widgets-shared-services'
import { LOAD_CUSTOM_FONTS, SHOW_POPUP } from './store/modules/view/types'
import { UrlUtils } from 'widgets-utils'
import { APPLY_COUPON_FROM_STORAGE } from './store/modules/coupon/types'
import { GuidStaticContent } from 'loyalty-shared-services'

export class ReferredFriendWidget implements Widget {
  private element!: Element
  private instanceId!: string
  private merchantData!: MerchantMetadata
  private staticContent!: StaticContent
  private overridenCustomizations!: MapObject
  private instanceVersionId!: string
  private guidStaticContent!: GuidStaticContent

  init (widgetOptions: WidgetOptions): void {
    this.element = widgetOptions.element
    this.instanceId = this.element.getAttribute('data-yotpo-instance-id') || ''
    this.instanceVersionId = widgetOptions.metadata.instanceVersionId || ''
    this.merchantData = widgetOptions.merchantData
    this.staticContent = widgetOptions.metadata.staticContent
    const customizations = this.getLowerCaseKeysMap(widgetOptions.metadata.customizations)
    this.overridenCustomizations = this.getCustomizations(customizations)
    this.guidStaticContent = widgetOptions.guidStaticContent
  }

  run () {
    const parentElement = this.element.parentNode
    const store = StoreCreator.createStore(
      this.overridenCustomizations,
      this.staticContent,
      this.merchantData.guid,
      this.instanceId,
      this.instanceVersionId,
      this.guidStaticContent
    )
    const vue = new Vue({
      store,
      render: h => h(App)
    }).$mount(this.element)

    if (this.shouldShowPopup()) {
      store.dispatch(LOAD_CUSTOM_FONTS, { parentElement })
      store.dispatch(SHOW_POPUP, { parentElement })
      dispatchTrackEvent(store.dispatch, ReferredFriendWidgetEvent.create({ action: 'loaded' }))
    } else {
      store.dispatch(APPLY_COUPON_FROM_STORAGE)
    }
  }
  private getCustomizations (customizations: { [key:string]: string }) {
    const urlParams = this.getQueryParams()
    const elementAttributes = this.getElementAttributes()
    return {
      ...customizations,
      ...elementAttributes,
      ...urlParams
    }
  }

  private shouldShowPopup (): boolean {
    if (this.overridenCustomizations['mode-read-only'] === 'true') {
      return true
    }
    const urlParams = this.getQueryParams()
    return urlParams.sref_id !== undefined && urlParams.sref_id !== ''
  }
  private getElementAttributes (): { [key:string]: string } {
    const attributes: { [key:string]: string } = {}
    for (var i = 0, atts = this.element.attributes, n = atts.length; i < n; i++) {
      const attribueName = atts[i].nodeName
      attributes[attribueName.toLowerCase()] = (atts[i].nodeValue || '').toString()
    }
    return attributes
  }
  private getQueryParams (): { [key: string]: string; } {
    const queryString = window.location.search.substring(1)
    return UrlUtils.getQueryParams(queryString)
  }

  private getLowerCaseKeysMap (map: { [key:string]: string }): { [key:string]: string } {
    const loweCaseKeysMap: { [key:string]: string } = {}

    Object.keys(map).forEach(key => {
      loweCaseKeysMap[key.toLowerCase()] = map[key]
    })

    return loweCaseKeysMap
  }
}
