import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { CouponState } from './types'

export const state: CouponState = {
  couponCode: null,
  errorType: null,
  isLoading: false,
  shouldAutoApply: false
}

class StoreModuleCreator {
  create (): Module<CouponState, any> {
    return {
      state,
      actions,
      mutations
    }
  }
}

export default new StoreModuleCreator()
