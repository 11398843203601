import { DefaultBoolean, DefaultOnlyNumber, DefaultOnlyString } from 'widgets-shared-services'
import { RootState } from '../main/types'

export const FETCH_EXCHANGE_RATE = 'currency/FETCH_EXCHANGE_RATE'
export const FETCH_CART_CURRENCY = 'currency/FETCH_CART_CURRENCY'
export const SET_EXCHANGE_RATE = 'currency/SET_EXCHANGE_RATE'
export const SET_CART_CURRENCY = 'currency/SET_CART_CURRENCY'

export interface CurrencyState {
  exchangeRate: number
  cartCurrency: string
  baseCurrency: string
  isMultiCurrencyEnabled: boolean
}

export interface CurrencyStateCustomization {
  exchangeRate: DefaultOnlyNumber
  cartCurrency: DefaultOnlyString
  baseCurrency: DefaultOnlyString
  isMultiCurrencyEnabled: DefaultBoolean
}

export interface ContextCommit {
  getters: any
  commit: any
  dispatch: any
  rootState: RootState
  state: CurrencyState
}
