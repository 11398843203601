export const TRACK_EVENT = 'analytics/TRACK_EVENT'
export const TRACK_START_BUTTON_CLICKED = 'analytics/TRACK_START_BUTTON_CLICKED'
export const TRACK_COPY_CODE_BUTTON_CLICKED = 'analytics/TRACK_COPY_CODE_BUTTON_CLICKED'
export const TRACK_CLOSE_BUTTON_CLICKED = 'analytics/TRACK_CLOSE_BUTTON_CLICKED'
export const TRACK_ERROR = 'analytics/TRACK_ERROR'

export interface AnalyticsState {
  guid: string
  instanceId: string
  instanceVersionId: string
  sessionId: string
  sequence: number
}
