export const dummyCoupon = {
  successful: {
    couponCode: 'C9P14JGA'
  },
  notEligible: {
    couponCode: null
  },
  alreadyUsed: {
    couponCode: 'C9P14JGA',
    uses: 1
  },
  applePrivateRelayIp: {
    isApplePrivateRelayIp: true
  }
}
