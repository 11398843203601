import { RootState } from '../main/types'

export const FETCH_LOGGED_IN_CUSTOMER = 'customer/FETCH_LOGGED_IN_CUSTOMER'
export const SET_LOGGED_IN_CUSTOMER = 'customer/SET_LOGGED_IN_CUSTOMER'
export const LOCAL_STORAGE_REFERRED_EMAIL_KEY = 'referred_email'
export const ON_CUSTOMER_EMAIL_SUBMIT = 'customer/ON_CUSTOMER_EMAIL_SUBMIT'

export interface CustomerState {
  email: string
}

export interface ContextCommit {
  commit: any
  dispatch: any
  rootState: RootState
  state: CustomerState
  getters: any
}
