import { Module } from 'vuex'
import { AppModeState, AppModeStateCustomizations } from '@/store/modules/mode/types'
import { Customizations } from 'widgets-shared-services'
import { ObjectUtils } from 'widgets-utils'
import { mutations } from './mutations'

export const initialModeState: AppModeStateCustomizations = {
  reflection: {
    default: false,
    key: 'mode-reflection'
  },
  screen: {
    default: '',
    key: 'mode-screen'
  },
  isEmailActivation: {
    default: false,
    key: 'email-activation'
  },
  isReadOnly: {
    default: false,
    key: 'mode-read-only'
  },
  isPreview: {
    default: false,
    key: 'mode-preview'
  },
  invalidEmail: {
    default: false,
    key: 'mode-invalid-email'
  },
  notEligible: {
    default: false,
    key: 'mode-not-eligible'
  },
  alreadyUsed: {
    default: false,
    key: 'mode-already-used'
  },
  generalFailure: {
    default: false,
    key: 'mode-general-failure'
  },
  applePrivateRelayIp: {
    default: false,
    key: 'mode-apple-private-relay-ip'
  }
}

class StoreModuleCreator {
  create (customizationsOverrides: any): Module<AppModeState, any> {
    const cleanedAppModeCustomizations = ObjectUtils.removeEmptyFields(customizationsOverrides)
    const mappedValues = Customizations.mapValuesToKeys(initialModeState, cleanedAppModeCustomizations)
    const initial = mappedValues as AppModeState

    return {
      state: initial,
      mutations
    }
  }
}

export default new StoreModuleCreator()
