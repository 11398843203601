import { Magento2Cart } from 'loyalty-shared-services'
import { LocalStorage } from 'widgets-shared-services'
import { CouponService } from './types'

export class Magento2Coupon implements CouponService {
  private cart: Magento2Cart

  constructor (cart: Magento2Cart) {
    this.cart = cart
  }

  async applyCoupon (couponCode: string): Promise<any> {
    const hasItemsInCart = await this.cart.hasItemsInCart()
    if (!hasItemsInCart) {
      return
    }
    LocalStorage.remove('loyalty-coupon-code')
    return this.cart.addCoupon(couponCode)
  }
}
