import { PixelEvent } from 'widgets-shared-services'

export class ReferredFriendWidgetEvent {
  static create (event: PixelEvent): PixelEvent {
    return {
      ...event,
      category: 'referred-friend'
    }
  }
}
