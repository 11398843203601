import { BigCommerceCart, CommerceCloudCart, LoyaltyApi, Magento2Cart } from 'loyalty-shared-services'
import { Magento2Coupon } from './platforms/magento2'
import { ShopifyCoupon } from './platforms/shopify-api'
import { BigCommerceCoupon } from './platforms/bigcommerce'
import { CommerceCloudCoupon } from './platforms/commerce-cloud'

export class PlatformCouponServiceFactory {
  static get (platformName: string, loyaltyApi: LoyaltyApi, guid: string, baseUrl?: string) {
    switch (platformName) {
      case 'shopify':
        return new ShopifyCoupon()
      case 'magento2':
        return new Magento2Coupon(new Magento2Cart(baseUrl))
      case 'bigcommerce':
        return new BigCommerceCoupon(new BigCommerceCart(loyaltyApi, guid))
      case 'commerce_cloud':
        return new CommerceCloudCoupon(new CommerceCloudCart(loyaltyApi, guid), loyaltyApi, guid)
      default:
        throw new Error(`${platformName} is not supported`)
    }
  }
}
