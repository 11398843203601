import { SET_CONTAINER_WIDTH, SET_CONTAINER_HEIGHT, SET_FIRST_ELEMENT, SET_IPAD_LANDSCAPE, SET_IPAD_PORTRAIT, SET_LAST_ELEMENT, SHOW_POPUP, ViewState } from './types'

export const mutations = {
  [SET_CONTAINER_WIDTH] (state: ViewState, width: number) {
    state.containerWidth = width
  },
  [SET_CONTAINER_HEIGHT] (state: ViewState, height: number) {
    state.containerHeight = height
  },
  [SET_IPAD_LANDSCAPE] (state: ViewState, isIpadLandscape: boolean) {
    state.isIpadLandscape = isIpadLandscape
  },
  [SET_IPAD_PORTRAIT] (state: ViewState, isIpadPortrait: boolean) {
    state.isIpadPortrait = isIpadPortrait
  },
  [SHOW_POPUP] (state: ViewState, showPopup: boolean) {
    state.showPopup = showPopup
  },
  [SET_FIRST_ELEMENT] (state: ViewState, selector: string) {
    state.popupFocus = {
      ...state.popupFocus,
      firstElementSelector: selector
    }
  },
  [SET_LAST_ELEMENT] (state: ViewState, selector: string) {
    state.popupFocus = {
      ...state.popupFocus,
      lastElementSelector: selector
    }
  }
}
