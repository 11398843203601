import { Module } from 'vuex'
import { PlatformState } from './types'
import { getters } from './getters'

export const initialPlatformState: PlatformState = {
  name: ''
}

class StoreModuleCreator {
  create (platformName: string): Module<PlatformState, any> {
    const initial = {
      ...initialPlatformState,
      name: platformName
    } as PlatformState

    return {
      state: initial,
      getters
    }
  }
}

export default new StoreModuleCreator()
