









































import { Vue, Component } from 'vue-property-decorator'
import CopyIcon from './icons/CopyIcon.vue'
import SuccessIcon from './icons/SuccessIcon.vue'

const CopyCodeButtonProps = Vue.extend({
  props: {
    code: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    fontFamily: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: '#011247'
    },
    copiedText: {
      type: String,
      default: ''
    },
    copyCodeIconUrl: {
      type: String,
      default: ''
    }
  }
})
@Component({
  components: {
    CopyIcon,
    SuccessIcon
  }
})
export default class CopyCodeButton extends CopyCodeButtonProps {
  private isCopied = false
  private animationDuration = 3500
  mounted () {
    this.$emit('copyCodeRef', this.$refs.copyCodeButton)
  }
  onCopyCodeClick () {
    this.isCopied = true
    const couponCodeElement = document.createElement('textarea')
    couponCodeElement.value = this.code
    const bodyElement = document.querySelector('body')
    if (bodyElement) {
      bodyElement.appendChild(couponCodeElement)
      couponCodeElement.select()
      couponCodeElement.setSelectionRange(0, this.code.length)
      document.execCommand('copy')
      couponCodeElement.remove()
    }
    this.$emit('onCopied')
    setTimeout(() => {
      this.isCopied = false
    }, this.animationDuration)
  }
}
