import { ContextCommit, FETCH_CART_CURRENCY, FETCH_EXCHANGE_RATE, SET_CART_CURRENCY, SET_EXCHANGE_RATE } from './types'
import { CartCurrency, ExchangeRateProviderFactory } from 'loyalty-shared-services'
import { TRACK_ERROR } from '../analytics/types'

export const actions = {
  async [FETCH_CART_CURRENCY] ({ dispatch, commit, rootState, state }: ContextCommit) {
    if (!state.isMultiCurrencyEnabled || rootState.mode.isReadOnly || rootState.mode.isPreview) {
      return
    }
    try {
      const cartCurrency = await CartCurrency.getCurrency(rootState.platform.name)
      if (cartCurrency !== state.baseCurrency) {
        commit(SET_CART_CURRENCY, cartCurrency)
        dispatch(FETCH_EXCHANGE_RATE)
      }
    } catch (error) {
      dispatch(TRACK_ERROR, { label: 'cart_currency_fetch_error', errorMessage: error.message })
    }
  },
  async [FETCH_EXCHANGE_RATE] ({ dispatch, commit, state, rootState }: ContextCommit) {
    try {
      const exchangeRateProvider = ExchangeRateProviderFactory.getExchangeRateProvider(rootState.platform.name, rootState.staticContent.guid, state.baseCurrency)
      const exchangeRate: number = await exchangeRateProvider.getExchangeRate(state.cartCurrency)
      if (exchangeRate) {
        commit(SET_EXCHANGE_RATE, exchangeRate)
      }
    } catch (error) {
      dispatch(TRACK_ERROR, { label: 'exchange_rate_fetch_error', errorMessage: error.message })
    }
  }
}
