import axios from 'axios'
import { BigCommerceCart } from 'loyalty-shared-services'
import { LocalStorage } from 'widgets-shared-services'
import { CouponService } from './types'

export class BigCommerceCoupon implements CouponService {
  private cart: BigCommerceCart

  constructor (cart: BigCommerceCart) {
    this.cart = cart
  }

  async applyCoupon (couponCode: string): Promise<any> {
    const cartContent: any = await this.cart.getCartContents()
    if (!cartContent || !cartContent.id || !this.cart.hasItemsInCart()) {
      return
    }

    LocalStorage.remove('loyalty-coupon-code')
    return this.cart.applyCoupon(cartContent.id, couponCode)
  }
}
