import { getContainerHeight } from '@/services/container-width'
import { DefaultString, DefaultBoolean, DefaultStringWithGetter, DefaultOnlyBoolean, DefaultOnlyNumber } from 'widgets-shared-services'
import { RootState } from '../main/types'
export const LOAD_CUSTOM_FONTS = 'view/LOAD_CUSTOM_FONTS'
export const SET_CONTAINER_DIMENSIONS = 'view/SET_CONTAINER_DIMENSIONS'
export const SET_CONTAINER_WIDTH = 'view/SET_CONTAINER_WIDTH'
export const SET_CONTAINER_HEIGHT = 'view/SET_CONTAINER_HEIGHT'
export const SET_IPAD_LANDSCAPE = 'view/SET_IPAD_LANDSCAPE'
export const SET_IPAD_PORTRAIT = 'view/SET_IPAD_PORTRAIT'
export const SHOW_POPUP = 'view/SHOW_POPUP'
export const SET_FIRST_ELEMENT = 'view/SET_FIRST_ELEMENT'
export const SET_LAST_ELEMENT = 'view/SET_LAST_ELEMENT'
export const HANDLE_POPUP_TAB_TRAP = 'view/HANDLE_POPUP_TAB_TRAP'
export const FOCUS_ON_ELEMENT = 'view/FOCUS_ON_ELEMENT'

export interface ViewState {
  showPopup: boolean
  backgroundColor: string
  popupBackgroundColor: string
  couponBackgroundColor: string
  containerWidth: number
  containerHeight: number
  isMobile: boolean
  isIpadLandscape: boolean
  isIpadPortrait: boolean
  fonts: FontsStyle
  emailTitle: TextStyle
  title: TextStyle
  emailCaptureDescription: TextStyle
  description: TextStyle
  copyIconColor: string
  copyCodeIconType: string
  backgroundImageUrl: string
  backgroundImageUrlMobile: string
  nextButton: ButtonStyle
  startButton: ButtonStyle
  popupFocus?: Partial<PopupFocus>
}

export interface ViewCustomizations {
  showPopup: DefaultOnlyBoolean
  isMobile: DefaultBoolean
  isIpadLandscape: DefaultBoolean
  isIpadPortrait: DefaultBoolean
  containerWidth: DefaultOnlyNumber
  containerHeight: DefaultOnlyNumber
  popupBackgroundColor: DefaultString
  couponBackgroundColor: DefaultString
  backgroundColor: DefaultString
  fonts: FontsCustomizations
  emailTitle: TextStyleCustomizations
  title: TextStyleCustomizations
  emailCaptureDescription: TextStyleCustomizations
  description: TextStyleCustomizations
  copyIconColor: DefaultString
  copyCodeIconType: DefaultString
  backgroundImageUrl: DefaultString
  backgroundImageUrlMobile: DefaultString
  nextButton: ButtonCustomizations
  emailErrorMessage: ErrorCustomizations
  startButton: ButtonCustomizations
  codeErrorMessage: ErrorCustomizations,
  errorLinkColor: DefaultString
}

export interface ErrorStyle {
  textColor: string
  fontSize: string
  fontWeight: string
}

export interface ErrorCustomizations {
  textColor: DefaultString
  fontSize: DefaultStringWithGetter
  fontWeight: DefaultString
}

export interface ButtonStyle {
  textColor: string
  backgroundColor: string
  type: string
}

export interface ButtonCustomizations {
  textColor: DefaultString
  backgroundColor: DefaultString
  type: DefaultString
}

export interface TextStyle {
  color: string
  font: {
    size: string
  }
}

export interface TextStyleCustomizations {
  color: DefaultString
  font: {
    size: DefaultStringWithGetter
  }
}

export interface FontsCustomizations {
  primaryFont: FontStyleCustomizations
  secondaryFont: FontStyleCustomizations
}

export interface FontsStyle {
  primaryFont: FontStyle
  secondaryFont: FontStyle
}

export interface FontStyle {
  family: string;
  url: string;
  weight: string;
  style: string;
}

export interface FontStyleCustomizations {
  family: DefaultStringWithGetter;
  url: DefaultStringWithGetter;
  weight: DefaultStringWithGetter;
  style: DefaultStringWithGetter;
}

export interface PopupFocus {
  firstElementSelector: string
  lastElementSelector: string
}

export interface ContextCommit {
  commit: any
  dispatch: any
  rootState: RootState
  state: ViewState
}
