export const getContainerWidth = (isMobile: boolean, isReadOnly: boolean, element?: HTMLElement): number => {
  if (isReadOnly) {
    return isMobile ? 375 : 1200
  }
  if (element) {
    return element.clientWidth
  }

  return 0
}

export const getContainerHeight = (element?: HTMLElement): number => {
  if (element) {
    return element.clientHeight
  }

  return 0
}
