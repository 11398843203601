import { ContextCommit, LOAD_CUSTOM_FONTS, SET_CONTAINER_DIMENSIONS, SET_CONTAINER_WIDTH, SET_IPAD_LANDSCAPE, SET_IPAD_PORTRAIT, SHOW_POPUP, HANDLE_POPUP_TAB_TRAP, FOCUS_ON_ELEMENT, SET_CONTAINER_HEIGHT } from './types'
import { FontFamilyAndUrl, FontService } from 'widgets-shared-services'
import { getContainerHeight, getContainerWidth } from '@/services/container-width'
import { FETCH_CART_CURRENCY } from '../currency/types'
import { FETCH_LOGGED_IN_CUSTOMER } from '../customer/types'

export const actions = {
  [LOAD_CUSTOM_FONTS] ({ rootState }: ContextCommit, args : {parentElement: Element}) {
    let fontsToLoad: FontFamilyAndUrl[] = [rootState.view.fonts.primaryFont, rootState.view.fonts.secondaryFont]
    fontsToLoad.forEach((fontUrl: FontFamilyAndUrl) => {
      FontService.loadFont(fontUrl, args.parentElement)
    })
  },
  async [SET_CONTAINER_DIMENSIONS] ({ commit, state, rootState }: ContextCommit, element: HTMLElement) {
    const elementWidth = getContainerWidth(!!state.isMobile, rootState.mode.isReadOnly, element)
    const elementHeight = getContainerHeight(element)
    commit(SET_CONTAINER_WIDTH, elementWidth)
    commit(SET_CONTAINER_HEIGHT, elementHeight)

    if (elementWidth > 768 && elementWidth <= 1024) {
      commit(SET_IPAD_LANDSCAPE, true)
    } else if (elementWidth === 768) {
      commit(SET_IPAD_PORTRAIT, true)
    } else {
      commit(SET_IPAD_LANDSCAPE, false)
      commit(SET_IPAD_PORTRAIT, false)
    }
  },
  [SHOW_POPUP] ({ commit, dispatch }: ContextCommit) {
    commit(SHOW_POPUP, true)
    dispatch(FETCH_LOGGED_IN_CUSTOMER)
    dispatch(FETCH_CART_CURRENCY)
  },
  [HANDLE_POPUP_TAB_TRAP] ({ state, dispatch }: ContextCommit, args: { event: Event, isForward: boolean }) {
    if (!state.popupFocus || (!state.popupFocus.firstElementSelector && !state.popupFocus.lastElementSelector)) {
      return
    }
    if (args.isForward) {
      const lastElement = document.querySelector(state.popupFocus.lastElementSelector!)
      if (document.activeElement === lastElement) {
        dispatch(FOCUS_ON_ELEMENT, { selector: state.popupFocus.firstElementSelector, event: args.event })
      }
    } else if (!args.isForward && state.popupFocus.firstElementSelector) {
      const firstElement = document.querySelector(state.popupFocus.firstElementSelector!)
      if (document.activeElement === firstElement) {
        dispatch(FOCUS_ON_ELEMENT, { selector: state.popupFocus.lastElementSelector, event: args.event })
      }
    }
  },
  [FOCUS_ON_ELEMENT] ({ rootState }: ContextCommit, args: { selector: string, event: Event }) {
    if (rootState.mode.isReadOnly) {
      return
    }
    const element = document.querySelector(args.selector) as HTMLElement
    if (element) {
      args.event.preventDefault()
      element.focus()
    }
  }
}
