




























import { Component, Vue } from 'vue-property-decorator'

const CustomizedTextProps = Vue.extend({
  props: {
    text: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#373330'
    },
    fontSize: {
      type: String,
      default: '40px'
    },
    fontFamily: {
      type: String,
      default: 'Nunito Sans'
    },
    fontWeight: {
      type: String,
      default: '400'
    },
    fontStyle: {
      type: String,
      default: 'normal'
    },
    linkColor: {
      type: String,
      default: '#0042E4'
    },
    isLinkableText: {
      type: Boolean,
      default: false
    }
  }
})

@Component
export default class CustomizedText extends CustomizedTextProps {
  get parseText () {
    if (!this.isLinkableText) return [{ text: this.text }]

    // Example: "I agree to the [privacy policy](https://example.net) of your store."
    // The regex Split command return value is an array:
    //    array[0] - "I agree to the"       (or empty in case of empty string before the link pattern)
    //    array[1] - "privacy policy"       (only in case of link)
    //    array[2] - "https://example.net"  (only in case of link)
    //    array[3] - "of your store."       (or empty in case of empty string after the link pattern)

    let splittedText = this.text.split(/\[([^\]]+)\]\(([^)]+)\)/g)
    let textParts = []

    for (let i = 0; i < splittedText.length; i += 3) {
      textParts.push({ text: splittedText[i] })

      if (splittedText.length >= i + 2) {
        textParts.push({ text: splittedText[i + 1], href: splittedText[i + 2] })
      }
    }

    return textParts
  }
}

