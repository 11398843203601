import { ViewState, ViewCustomizations } from './types'
import { Module } from 'vuex'
import { ObjectUtils } from 'widgets-utils'
import { MapObject, Customizations, FontService } from 'widgets-shared-services'
import { actions } from './actions'
import { mutations } from './mutations'

export const initialViewState: ViewCustomizations = {
  showPopup: {
    default: false
  },
  isMobile: {
    key: 'is-mobile',
    default: false
  },
  isIpadLandscape: {
    key: 'is-ipad-landscape',
    default: false
  },
  isIpadPortrait: {
    key: 'is-ipad-portrait',
    default: false
  },
  containerWidth: {
    default: 0
  },
  containerHeight: {
    default: 0
  },
  emailTitle: {
    font: {
      size: {
        key: 'email-capture-title-font-size',
        default: '26',
        getter: function (size: string | number): string {
          return FontService.getPixelFontSize(size)
        }
      }
    },
    color: {
      default: '#59564f',
      key: 'email-capture-title-color'
    }
  },
  title: {
    font: {
      size: {
        key: 'title-font-size',
        default: '26',
        getter: function (size: string | number): string {
          return FontService.getPixelFontSize(size)
        }
      }
    },
    color: {
      default: '#59564f',
      key: 'title-color'
    }
  },
  emailCaptureDescription: {
    font: {
      size: {
        key: 'email-capture-description-font-size',
        default: '18',
        getter: function (size: string | number): string {
          return FontService.getPixelFontSize(size)
        }
      }
    },
    color: {
      default: '#3e3a36',
      key: 'email-capture-description-color'
    }
  },
  description: {
    font: {
      size: {
        key: 'description-font-size',
        default: '18',
        getter: function (size: string | number): string {
          return FontService.getPixelFontSize(size)
        }
      }
    },
    color: {
      default: '#3e3a36',
      key: 'description-color'
    }
  },
  backgroundColor: {
    key: 'background-color',
    default: 'rgba(0, 0, 0, 0.7)'
  },
  popupBackgroundColor: {
    key: 'popup-background-color',
    default: '#ffffff'
  },
  couponBackgroundColor: {
    key: 'coupon-background-color',
    default: '#fafbfc'
  },
  fonts: {
    primaryFont: {
      family: {
        default: 'Montserrat@600|https://fonts.googleapis.com/css?family=Montserrat:600&display=swap',
        key: 'primary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontFamily(fontNameAndUrl, '|')
        }
      },
      url: {
        default: 'Montserrat@600|https://fonts.googleapis.com/css?family=Montserrat:600&display=swap',
        key: 'primary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontUrl(fontNameAndUrl, '|')
        }
      },
      weight: {
        default: 'Montserrat@600|https://fonts.googleapis.com/css?family=Montserrat:600&display=swap',
        key: 'primary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontWeight(fontNameAndUrl, '|')
        }
      },
      style: {
        default: 'Montserrat@600|https://fonts.googleapis.com/css?family=Montserrat:600&display=swap',
        key: 'primary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontStyle(fontNameAndUrl, '|')
        }
      }
    },
    secondaryFont: {
      family: {
        default: 'Nunito Sans@400|https://fonts.googleapis.com/css?family=Nunito+Sans:400&display=swap',
        key: 'secondary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontFamily(fontNameAndUrl, '|')
        }
      },
      url: {
        default: 'Nunito Sans@400|https://fonts.googleapis.com/css?family=Nunito+Sans:400&display=swap',
        key: 'secondary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontUrl(fontNameAndUrl, '|')
        }
      },
      weight: {
        default: 'Nunito Sans@400|https://fonts.googleapis.com/css?family=Nunito+Sans:400&display=swap',
        key: 'secondary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontWeight(fontNameAndUrl, '|')
        }
      },
      style: {
        default: 'Nunito Sans@400|https://fonts.googleapis.com/css?family=Nunito+Sans:400&display=swap',
        key: 'secondary-font-name-and-url',
        getter: function (fontNameAndUrl: string): string {
          return FontService.getFontStyle(fontNameAndUrl, '|')
        }
      }
    }
  },
  copyIconColor: {
    key: 'copy-code-icon-color',
    default: '#9EA1F0'
  },
  copyCodeIconType: {
    key: 'copy-code-icon-type',
    default: 'default'
  },
  backgroundImageUrl: {
    key: 'background-image-url',
    default: ''
  },
  backgroundImageUrlMobile: {
    key: 'background-image-url-mobile',
    default: ''
  },
  nextButton: {
    textColor: {
      key: 'next-button-text-color',
      default: '#ffffff'
    },
    backgroundColor: {
      key: 'next-button-background-color',
      default: '#393086'
    },
    type: {
      key: 'next-button-type',
      default: 'filled_rectangle'
    }
  },
  startButton: {
    textColor: {
      key: 'start-button-text-color',
      default: '#ffffff'
    },
    backgroundColor: {
      key: 'start-button-background-color',
      default: '#393086'
    },
    type: {
      key: 'start-button-type',
      default: 'filled_rectangle'
    }
  },
  emailErrorMessage: {
    textColor: {
      key: 'error-message-invalid-email-text-color',
      default: '#f04860'
    },
    fontSize: {
      key: 'error-message-font-size',
      default: '14',
      getter: function (size: string | number): string {
        return FontService.getPixelFontSize(size)
      }
    },
    fontWeight: {
      key: 'error-message-font-weight',
      default: '600'
    }
  },
  codeErrorMessage: {
    textColor: {
      key: 'error-message-text-color',
      default: '#f04860'
    },
    fontSize: {
      key: 'error-message-font-size',
      default: '14',
      getter: function (size: string | number): string {
        return FontService.getPixelFontSize(size)
      }
    },
    fontWeight: {
      key: 'error-message-font-weight',
      default: '600'
    }
  },
  errorLinkColor: {
    default: '#0042E4',
    key: 'error-link-color'
  }
}

class StoreModuleCreator {
  create (customizationsOverrides: MapObject): Module<ViewState, any> {
    const cleanedCustomizations = ObjectUtils.removeEmptyFields(customizationsOverrides) as MapObject
    const mappedValues = Customizations.mapValuesToKeys(initialViewState, cleanedCustomizations)
    if (!this.isOverridingMobile(cleanedCustomizations)) {
      mappedValues.isMobile = this.isMobile()
    }
    const initial = mappedValues as ViewState
    return {
      state: initial,
      actions,
      mutations
    }
  }

  private isOverridingMobile (cleanedCustomizations: MapObject): boolean {
    return typeof cleanedCustomizations['is-mobile'] !== 'undefined'
  }

  private isMobile (): boolean {
    if (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/\bAndroid(?:.+)Mobile\b/i) ||
      navigator.userAgent.match(/\bAndroid(?:.+)(?:KF[A-Z]{2,4})\b/i) ||
      navigator.userAgent.match(/\bokhttp\b/i) ||
      navigator.userAgent.match(/\bWindows(?:.+)ARM\b/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/BB10/i) ||
      navigator.userAgent.match(/Opera Mini/i) ||
      navigator.userAgent.match(/\b(CriOS|Chrome)(?:.+)Mobile/i) ||
      navigator.userAgent.match(/Mobile(?:.+)Firefox\b/i) ||
      navigator.userAgent.match(/Windows Phone/i)) {
      return true
    }
    return false
  }
}

export default new StoreModuleCreator()
