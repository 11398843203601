


















import { Vue } from 'vue-property-decorator'
export default class CopyIcon extends Vue {}
