import { RootState } from '../main/types'
export const FETCH_COUPON_CODE = 'coupon/FETCH_COUPON_CODE'
export const SET_COUPON_CODE = 'coupon/SET_COUPON_CODE'
export const SET_ERROR_TYPE = 'coupon/SET_ERROR_TYPE'
export const SET_COUPON_IS_LOADING = 'coupon/SET_COUPON_IS_LOADING'
export const SET_DUMMY_COUPON = 'coupon/SET_DUMMY_COUPON'
export const PROCESS_COUPON = 'coupon/PROCESS_COUPON'
export const APPLY_COUPON = 'coupon/APPLY_COUPON'
export const SET_SHOULD_AUTO_APPLY = 'coupon/SET_SHOULD_AUTO_APPLY'
export const APPLY_COUPON_FROM_STORAGE = 'coupon/APPLY_COUPON_FROM_STORAGE'

export interface CouponState {
  couponCode: string | null
  errorType: ErrorType | null
  isLoading: boolean
  shouldAutoApply: boolean
}

export interface ContextCommit {
  commit: any
  dispatch: any
  rootState: RootState
  state: CouponState
  getters: any
}

export enum ErrorType {
  NotEligible = 'not_eligible',
  UsedCoupon = 'used_coupon',
  General = 'general',
  IsApplePrivateRelayIp = 'is_apple_private_relay_ip'
}
