import { TRACK_EVENT, AnalyticsState, TRACK_START_BUTTON_CLICKED, TRACK_COPY_CODE_BUTTON_CLICKED, TRACK_CLOSE_BUTTON_CLICKED, TRACK_ERROR } from './types'
import { RootState } from '../main/types'
import { ReferredFriendWidgetEvent } from '@/models/referred-friend-widget-event'
import { YotpoAnalytics, PixelEvent } from 'widgets-shared-services'

interface ContextCommit {
  commit: any
  dispatch: any
  state: AnalyticsState
  rootState: RootState
}

export const dispatchTrackEvent = (dispatch: any, event: ReferredFriendWidgetEvent) => {
  dispatch(TRACK_EVENT, event)
}

export const actions = {
  async [TRACK_EVENT] ({ commit, state, rootState }: ContextCommit, event: PixelEvent) {
    if (rootState.mode.isReadOnly || rootState.mode.isPreview) {
      return
    }
    YotpoAnalytics.track(state.guid, {
      ...event,
      context: {
        ...event.context,
        session_id: state.sessionId,
        sequence: state.sequence.toString(),
        widget_instance_id: state.instanceId,
        instance_version_id: state.instanceVersionId,
        is_mobile: rootState.view.isMobile
      }
    })
    commit(TRACK_EVENT)
  },
  [TRACK_START_BUTTON_CLICKED] ({ dispatch }: ContextCommit) {
    dispatchTrackEvent(dispatch, ReferredFriendWidgetEvent.create({
      action: 'clicked_on',
      label: 'button_clicked',
      property: 'start_button_clicked'
    }))
  },
  [TRACK_COPY_CODE_BUTTON_CLICKED] ({ dispatch }: ContextCommit) {
    dispatchTrackEvent(dispatch, ReferredFriendWidgetEvent.create({
      action: 'clicked_on',
      label: 'button_clicked',
      property: 'copy_code_button_clicked'
    }))
  },
  [TRACK_CLOSE_BUTTON_CLICKED] ({ dispatch }: ContextCommit) {
    dispatchTrackEvent(dispatch, ReferredFriendWidgetEvent.create({
      action: 'clicked_on',
      label: 'button_clicked',
      property: 'close_button_clicked'
    }))
  },
  [TRACK_ERROR] ({ dispatch }: ContextCommit, args: { label: string, errorMessage: string }) {
    dispatchTrackEvent(dispatch, ReferredFriendWidgetEvent.create({
      action: 'error',
      label: args.label,
      context: {
        error_message: args.errorMessage
      }
    }))
  }
}
