












import { Component, Prop, Vue } from 'vue-property-decorator'
import { dispatchTrackEvent } from '../../store/modules/analytics/actions'
import { ReferredFriendWidgetEvent } from '../../models/referred-friend-widget-event'
import ReflectionContainer from './Reflection.vue'
import PopupLayout from './PopupLayout.vue'

@Component({
  components: {
    ReflectionContainer,
    PopupLayout
  }
})
export default class LayoutContainer extends Vue {
  get isReflection () {
    return this.$store.state.mode.reflection
  }
  get view () {
    return this.$store.state.view
  }
  get content () {
    return this.$store.state.content
  }
}
