import { CurrencyState, SET_CART_CURRENCY, SET_EXCHANGE_RATE } from './types'

export const mutations = {
  [SET_CART_CURRENCY] (state: CurrencyState, cartCurrency: string) {
    state.cartCurrency = cartCurrency
  },
  [SET_EXCHANGE_RATE] (state: CurrencyState, exchangeRate: number) {
    state.exchangeRate = exchangeRate
  }
}
