






















import { Component, Vue } from 'vue-property-decorator'
import { FontService } from 'widgets-shared-services'

const ActionButtonProps = Vue.extend({
  props: {
    buttonText: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#558342'
    },
    buttonColor: {
      type: String,
      default: '#ffffff'
    },
    disabledOutlineButtonColor: {
      type: String,
      default: '#929292'
    },
    buttonSize: {
      type: String,
      default: 'standard'
    },
    buttonType: {
      type: String,
      default: 'filled_rectangle'
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    fontFamily: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})

@Component({})
export default class ActionButton extends ActionButtonProps {
  onClick () {
    if (!this.isReadOnly && !this.isPreview) {
      this.$emit('onClick')
    }
  }
  get buttonSizeClass () {
    switch (this.buttonSize.toLowerCase()) {
      case 'small': {
        return 'yotpo-button-small-size'
      }
      case 'standard': {
        return 'yotpo-button-standard-size'
      }
      case 'large': {
        return 'yotpo-button-large-size'
      }
    }
    return 'yotpo-button-standard-size'
  }
  get buttonTypeClass () {
    const buttonTypeLowerCase = this.buttonType.toLowerCase()
    let buttonTypeClass
    switch (buttonTypeLowerCase) {
      case 'rectangular_outline':
      case 'filled_rectangle': {
        buttonTypeClass = 'yotpo-rectangular-btn-type'
        break
      }
      case 'rounded_rectangle':
      case 'rounded_filled_rectangle': {
        buttonTypeClass = 'yotpo-rounded-btn-type'
        break
      }
      case 'capsule_outline':
      case 'filled_capsule': {
        buttonTypeClass = 'yotpo-capsule-btn-type'
        break
      }
      case 'borderless': {
        buttonTypeClass = 'yotpo-borderless-btn-type'
        break
      }
    }
    return this.isFilled ? `yotpo-filled-button ${buttonTypeClass}` : buttonTypeClass
  }
  get cursorClass () {
    return this.disabled ? 'yotpo-default-cursor' : 'yotpo-pointer-cursor'
  }
  get isFilled () {
    return this.buttonType.toLowerCase().includes('filled')
  }
  get isBorderless () {
    return this.buttonType.toLowerCase().includes('borderless')
  }
  get buttonStyle () {
    const style = {
      background: this.buttonColor,
      color: this.textColor
    }

    if (this.isBorderless) {
      return { ...style, color: this.buttonColor, background: 'none' }
    }
    if (!this.isFilled) {
      const color = this.disabled ? this.disabledOutlineButtonColor : this.textColor
      return { ...style, color: color, background: 'none', border: `2px solid ${this.buttonColor}` }
    }
    return style
  }
}
