








































import { TRACK_CLOSE_BUTTON_CLICKED } from '@/store/modules/analytics/types'
import { HANDLE_POPUP_TAB_TRAP, SET_FIRST_ELEMENT } from '@/store/modules/view/types'
import { Vue, Component } from 'vue-property-decorator'
import { SafeWindow } from 'widgets-shared-services'
import { SET_CONTAINER_DIMENSIONS } from '../../store/modules/view/types'
import CloseIcon from '../widgets/icons/CloseIcon.vue'
import ReferredFriendCouponContainer from './ReferredFriendCouponContainer.vue'
import ReferredFriendEmailContainer from './ReferredFriendEmailContainer.vue'
import { Screens } from '@/models/screens'
import { ReferredFriendWidgetEvent } from '@/models/referred-friend-widget-event'
import { dispatchTrackEvent } from '@/store/modules/analytics/actions'

@Component({
  components: {
    CloseIcon,
    ReferredFriendCouponContainer,
    ReferredFriendEmailContainer
  }
})

export default class PopupLayoutContainer extends Vue {
  private isPopupOpen = true
  mounted () {
    if (this.mode.isPreview || this.mode.isReadOnly) {
      return
    }
    (this.$refs.closeButton as HTMLElement).focus()
    this.$store.commit(SET_FIRST_ELEMENT, '.yotpo-widget-referred-friend .yotpo-close-button')
    this.setContainerDimensions()
    SafeWindow.addEventListener('resize', this.onResize)

    this.dispatchLoadedEvent()
  }
  dispatchLoadedEvent (): void {
    dispatchTrackEvent(
      this.$store.dispatch,
      ReferredFriendWidgetEvent.create({
        action: 'shown',
        label: 'layout'
      })
    )
  }
  onResize (event: any): void {
    this.setContainerDimensions()
  }
  setContainerDimensions (): void {
    if (this.$refs.popup) {
      this.$store.dispatch(SET_CONTAINER_DIMENSIONS, this.$refs.popup)
    }
  }
  get mode () {
    return this.$store.state.mode
  }
  get view () {
    return this.$store.state.view
  }
  closePopup () {
    if (this.mode.isReadOnly || this.mode.isPreview) {
      return
    }
    this.isPopupOpen = false
  }
  closeButtonClick () {
    this.$store.dispatch(TRACK_CLOSE_BUTTON_CLICKED)
    this.closePopup()
  }
  startButtonClicked () {
    this.closePopup()
  }
  get overlayClasses () {
    if (this.view.isIpadLandscape) {
      return 'yotpo-is-ipad-landscape'
    } else if (this.view.isIpadPortrait) {
      return 'yotpo-is-ipad-portrait'
    } else if (this.view.isMobile) {
      return 'yotpo-is-mobile'
    } else if (this.view.containerWidth > 1024 && this.view.containerHeight < 650) {
      return 'yotpo-is-narrow-height-desktop'
    }
  }

  handleKeyDown (event: KeyboardEvent) {
    const KEY_TAB = 9
    const KEY_ESC = 27
    switch (event.keyCode) {
      case KEY_TAB:
        this.$store.dispatch(HANDLE_POPUP_TAB_TRAP, { event, isForward: !event.shiftKey })
        break
      case KEY_ESC:
        this.closePopup()
        break
    }
  }
  get screens () {
    return Screens
  }
}
