import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import contentModuleCreator from '@/store/modules/content/index'
import viewModuleCreator from '@/store/modules/view/index'
import staticContentModuleCreator from '@/store/modules/static-content/index'
import modeModuleCreator from '@/store/modules/mode/index'
import analyticsModuleCreator from '@/store/modules/analytics/index'
import couponModuleCreator from '@/store/modules/coupon/index'
import customerModuleCreator from '@/store/modules/customer/index'
import currencyModuleCreator from '@/store/modules/currency/index'
import platformModuleCreator from '@/store/modules/platform/index'
import { StaticContent } from '@/store/modules/static-content/types'
import { RootState } from './types'
import { GuidStaticContent } from 'loyalty-shared-services'

Vue.use(Vuex)

class StoreCreator {
  createStore (customizationsOverrides: any, staticContent: StaticContent, guid: string, instanceId: string, instanceVersionId: string, guidStaticContent: GuidStaticContent): Store<any> {
    staticContent.platformName = this.getPlatformName(staticContent, guidStaticContent)

    const viewModule = viewModuleCreator.create(customizationsOverrides)
    const contentModule = contentModuleCreator.create(customizationsOverrides)
    const staticContentModule = staticContentModuleCreator.create(staticContent, guid)
    const modeModule = modeModuleCreator.create(customizationsOverrides)
    const analyticsModule = analyticsModuleCreator.create(guid, instanceId, instanceVersionId)
    const couponModule = couponModuleCreator.create()
    const customerModule = customerModuleCreator.create()
    const currencyModule = currencyModuleCreator.create(customizationsOverrides, staticContent.currency, staticContent.isMultiCurrencyEnabled)
    const platformModule = platformModuleCreator.create(staticContent.platformName)
    return new Vuex.Store<RootState>({
      modules: {
        mode: modeModule,
        view: viewModule,
        content: contentModule,
        staticContent: staticContentModule,
        analytics: analyticsModule,
        coupon: couponModule,
        customer: customerModule,
        currency: currencyModule,
        platform: platformModule
      }
    })
  }
  getPlatformName (staticContent: StaticContent, guidStaticContent: GuidStaticContent): string {
    if (guidStaticContent && guidStaticContent.loyalty && guidStaticContent.loyalty.platformName) {
      return guidStaticContent.loyalty.platformName
    }
    return staticContent.platformName
  }
}

export default new StoreCreator()
