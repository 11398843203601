



















import { Component, Vue } from 'vue-property-decorator'
import CustomizedText from '../widgets/CustomizedText.vue'
import WarningIcon from '../widgets/icons/WarningIcon.vue'

const ErrorMessageProps = Vue.extend({
  props: {
    text: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: '#373330'
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    fontFamily: {
      type: String,
      default: 'Nunito Sans'
    },
    fontWeight: {
      type: String,
      default: '400'
    },
    backgroundColor: {
      type: String,
      default: '#fafbfc'
    },
    isLinkableText: {
      type: Boolean,
      default: false
    },
    linkColor: {
      type: String,
      default: '#0042E4'
    }
  }
})

@Component({
  components: {
    CustomizedText,
    WarningIcon
  }
})
export default class ErrorMessage extends ErrorMessageProps {}
