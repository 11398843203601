import { PlatformState } from './types'
import { RootState } from '../main/types'
import { BigcommerceApi, LoyaltyApi } from 'loyalty-shared-services'
import { PlatformCouponServiceFactory } from '@/services/platform-coupon-service-factory'

export const getters = {
  loyaltyApi: (state: PlatformState, getters: any, rootState: RootState): LoyaltyApi => {
    const baseUrl = process.env.VUE_APP_LOYALTY_BASE_URL || 'https://loyalty.yotpo.com'
    return new LoyaltyApi(baseUrl)
  },
  bigCommerceApi: (state: PlatformState, getters: any, rootState: RootState): BigcommerceApi => {
    return new BigcommerceApi(getters.loyaltyApi)
  },
  couponService: (state: PlatformState, getters: any, rootState: RootState): PlatformCouponServiceFactory => {
    return PlatformCouponServiceFactory.get(rootState.platform.name, getters.loyaltyApi, rootState.staticContent.guid, rootState.staticContent.baseUrl)
  }
}
