









































































import { Vue, Component } from 'vue-property-decorator'
import CustomizedText from '../widgets/CustomizedText.vue'
import ErrorMessage from '../widgets/ErrorMessage.vue'
import ActionButton from '../widgets/ActionButton.vue'
import { FontService } from 'widgets-shared-services'
import { SET_LAST_ELEMENT } from '@/store/modules/view/types'
import { ON_CUSTOMER_EMAIL_SUBMIT } from '@/store/modules/customer/types'
import { EmailUtils } from 'widgets-utils'

@Component({
  components: {
    CustomizedText,
    ActionButton,
    ErrorMessage
  }
})

export default class ReferredFriendEmailContainer extends Vue {
  private email = ''
  private isValidEmail = true
  private isFocused = false
  private readonly inputPlaceholder = 'youremail@email.com'
  mounted () {
    this.$store.commit(SET_LAST_ELEMENT, '.yotpo-widget-referred-friend .yotpo-next-button .yotpo-button-style')
  }
  get content () {
    return this.$store.state.content
  }
  get view () {
    return this.$store.state.view
  }
  get mode () {
    return this.$store.state.mode
  }
  get titleFontSize () {
    const fontSize = this.view.emailTitle.font.size
    if (this.view.isMobile) {
      return FontService.calulcateMobileFontSize(fontSize) + 'px'
    }
    return fontSize
  }
  get emailPlaceholder () {
    if (this.isFocused) {
      return ''
    }
    return this.inputPlaceholder
  }
  get emailStyle () {
    let fontFamily = this.view.fonts.secondaryFont.family
    let boxShadow = this.isFocused ? `0 0 1px 1px ${this.view.nextButton.backgroundColor}` : ''
    return { fontFamily: fontFamily, boxShadow: boxShadow }
  }
  get backgroundImage () {
    let backgroundImage = this.view.backgroundImageUrl
    if (this.view.isMobile || this.view.isIpadPortrait) {
      backgroundImage = this.view.backgroundImageUrlMobile
    }
    return {
      backgroundImage: `url('${backgroundImage}')`
    }
  }
  get templatedContent () {
    return this.$store.getters.templatedContent
  }
  get invalidEmail () {
    return this.mode.invalidEmail || !this.isValidEmail
  }
  inputKeyDown (event: any): void {
    this.isValidEmail = true
  }
  onNextButtonClicked () {
    this.isValidEmail = EmailUtils.validateEmailAddress(this.email)
    if (!this.isValidEmail) {
      return
    }
    this.$store.dispatch(ON_CUSTOMER_EMAIL_SUBMIT, this.email)
  }
}
