import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { CurrencyState, CurrencyStateCustomization } from './types'
import { Customizations } from 'widgets-shared-services'
import { ObjectUtils } from 'widgets-utils'

export const initialCurrencyState: CurrencyStateCustomization = {
  exchangeRate: {
    default: 1.0
  },
  baseCurrency: {
    default: ''
  },
  cartCurrency: {
    default: ''
  },
  isMultiCurrencyEnabled: {
    key: 'multi-currency',
    default: false
  }
}

class StoreModuleCreator {
  create (customizations: Customizations, baseCurrency: string, isMultiCurrencyEnabled: boolean) {
    const cleanedCustomizations: CurrencyStateCustomization = ObjectUtils.removeEmptyFields(customizations)
    const mappedValues = Customizations.mapValuesToKeys(initialCurrencyState, cleanedCustomizations)
    let initial = mappedValues as CurrencyState
    initial.baseCurrency = baseCurrency
    initial.cartCurrency = baseCurrency
    if (isMultiCurrencyEnabled !== null) {
      initial.isMultiCurrencyEnabled = isMultiCurrencyEnabled
    }
    return {
      state: initial,
      getters,
      actions,
      mutations
    }
  }
}

export default new StoreModuleCreator()
