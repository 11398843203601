import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { AnalyticsState } from './types'
const uuidv4 = require('uuid/v4')

export const initialAnalyticsState: AnalyticsState = {
  guid: '',
  instanceId: '',
  instanceVersionId: '',
  sequence: 0,
  sessionId: ''
}
class StoreModuleCreator {
  create (guid: string, instanceId: string, instanceVersionId: string): Module<AnalyticsState, any> {
    const initial: AnalyticsState = {
      ...initialAnalyticsState,
      guid: guid,
      instanceId: instanceId,
      instanceVersionId: instanceVersionId,
      sessionId: uuidv4()
    }
    return {
      state: initial,
      actions,
      mutations
    }
  }
}

export default new StoreModuleCreator()
