














import { Component, Prop, Vue } from 'vue-property-decorator'
import { Customizations } from 'widgets-shared-services'
import { initialModeState } from '@/store/modules/mode'
import { initialContentState } from '@/store/modules/content'
import { initialViewState } from '@/store/modules/view'

@Component
export default class ReflectionContainer extends Vue {
  get mode () {
    return this.$store.state.mode
  }
  get content () {
    return this.$store.state.content
  }
  get view () {
    return this.$store.state.view
  }
  get customizationKeys () {
    const modeCustomizationKeys = Customizations.getCustomizationKeys(initialModeState)
    const viewCustomizationKeys = Customizations.getCustomizationKeys(initialViewState)
    const contentCustomizationKeys = Customizations.getCustomizationKeys(initialContentState)

    return { mode: modeCustomizationKeys, view: viewCustomizationKeys, content: contentCustomizationKeys }
  }
}
