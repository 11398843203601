import { ContentState, ContentCustomizations } from './types'
import { Module } from 'vuex'
import { Customizations } from 'widgets-shared-services'
import { ObjectUtils } from 'widgets-utils'
import { getters } from './getters'

export const initialContentState: ContentCustomizations = {
  emailTitleText: {
    default: '',
    key: 'email-capture-title-text'
  },
  titleText: {
    default: '',
    key: 'title-text'
  },
  emailDescriptionText: {
    default: '',
    key: 'email-capture-description-text'
  },
  descriptionText: {
    default: '',
    key: 'description-text'
  },
  nextButtonText: {
    default: '',
    key: 'next-button-text'
  },
  invalidEmailText: {
    default: '',
    key: 'error-message-invalid-email'
  },
  startButtonText: {
    default: '',
    key: 'start-button-text'
  },
  copiedText: {
    default: '',
    key: 'copy-code-copied-text'
  },
  copyCodeIconUrl: {
    default: '',
    key: 'copy-code-icon-url'
  },
  generalFailureText: {
    default: '',
    key: 'error-general-failure-text'
  },
  notEligibleCouponText: {
    default: '',
    key: 'error-not-eligible-coupon-text'
  },
  alreadyUsedCouponText: {
    default: '',
    key: 'error-already-used-coupon-text'
  },
  isApplePrivateRelayIpText: {
    default: 'To get the discount code you’ll need to turn off the "Hide IP address" option in your Safari privacy settings, then click the referral link again.',
    key: 'error-safari-hide-ip-text'
  }
}

class StoreModuleCreator {
  create (customizations: ContentCustomizations): Module<ContentState, any> {
    const cleanedCustomizations: ContentCustomizations = ObjectUtils.removeEmptyFields(customizations)
    const mappedValues = Customizations.mapValuesToKeys(initialContentState, cleanedCustomizations)
    const initial = mappedValues as ContentState

    return {
      state: ObjectUtils.htmlDecodeDeep(initial),
      getters
    }
  }
}

export default new StoreModuleCreator()
