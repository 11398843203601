
















import { Component, Vue } from 'vue-property-decorator'

const BallsLoaderProps = Vue.extend({
  props: {
    backgroundColor: {
      type: String,
      default: '#3e3a36'
    }
  }
})

@Component({})
export default class BallsLoader extends BallsLoaderProps {}
