<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    class="yotpo-widget-referred-friend-success-icon"
    viewBox="0 0 15 15"
    role="img"
    aria-hidden="true"
    focusable="false"
  >
    <title>V icon</title>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M14.921 3.361l-1.354-1.316-8.267 8.03-3.945-3.832L0 7.559l5.3 5.149z"
    />
  </svg>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class SuccessIcon extends Vue {}
</script>
