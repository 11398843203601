
import { AppModeState, SET_SCREEN, SET_INVALID_EMAIL_MODE } from '@/store/modules/mode/types'
import { Screens } from '@/models/screens'

export const mutations = {
  [SET_SCREEN] (state: AppModeState, screen: Screens) {
    state.screen = screen
  },

  [SET_INVALID_EMAIL_MODE] (state: AppModeState, valid: boolean) {
    state.invalidEmail = valid
  }
}
