








import { Component, Vue } from 'vue-property-decorator'
import LayoutContainer from './components/containers/Layout.vue'

@Component({
  components: {
    LayoutContainer
  }
})
export default class App extends Vue {}
