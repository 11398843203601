import { mutations } from './mutations'
import { actions } from './actions'
import { CustomerState } from './types'
import { Module } from 'vuex'

export const state: CustomerState = {
  email: ''
}

class StoreModuleCreator {
  create (): Module<CustomerState, any> {
    return {
      state,
      mutations,
      actions
    }
  }
}

export default new StoreModuleCreator()
