import { RootState } from '../main/types'
import { ContentState } from './types'
import { Variables, Render } from 'widgets-shared-services'

const CURRENCY_PATTERN = '\\d+(\\.\\d{1,2})?'

export const getters = {
  exchangeCurrencyRate: (state: ContentState, getters: any) => (variable: string) => {
    var amountToConvert = variable.match(new RegExp(`${CURRENCY_PATTERN}`, 'g'))
    if (amountToConvert === null) {
      return variable
    }
    return getters.currencyConversion.convertAndFormat(amountToConvert[0], false, 2, false)
  },
  getTemplateInShopperCurrency: (state: ContentState, getters: any) => (template: string) => {
    let templateInShopperCurrency = template
    const getVariableOnShopperCurrency = (variable: string): string => {
      return getters.exchangeCurrencyRate(variable)
    }
    templateInShopperCurrency = templateInShopperCurrency.replace(new RegExp(`{{${CURRENCY_PATTERN}_local_currency}}`, 'g'),
      getVariableOnShopperCurrency)

    return templateInShopperCurrency
  },
  templatedContent: (state: ContentState, getters: any, rootState: RootState) => (template: string, overrides?: Variables) => {
    let variables = { }
    if (overrides) {
      variables = {
        ...variables,
        ...overrides
      }
    }
    template = getters.getTemplateInShopperCurrency(template)
    return Render.template(state, getters, rootState, template, variables)
  },
  getTemplateOrError: (state: ContentState, getters: any) => (template: string, error: string, isError: boolean) => {
    if (isError) {
      return error
    }
    return getters.templatedContent(template)
  }
}
